export default class StickyHeader {
  constructor(el) {
    this.$el = el;
    this.$welcomeSection = this.$el.querySelector(".header__welcome");
    this.$name = this.$el.querySelector(".header__welcome-name");
    this.$message = this.$el.querySelector(".header__welcome-message");
    this.$collapsableLetters = this.$el.querySelectorAll(
      ".header__welcome-letter--collapse"
    );
    this.$dottedLetters = this.$el.querySelectorAll(
      ".header__welcome-letter--dot"
    );
    this.$spaceLetters = this.$el.querySelectorAll(
      ".header__welcome-letter--space"
    );

    this.initMatchMedia();
  }

  initMatchMedia() {
    const mm = gsap.matchMedia();

    mm.add("(min-width: 1025px)", () => {
      this.initAnimation(true);
    });

    mm.add("(max-width: 1024px)", () => {
      this.initAnimation(false);
    });
  }

  createInitialsTimeline() {
    const tlInitials = gsap.timeline({ paused: true });

    tlInitials.to(
      this.$collapsableLetters,
      {
        duration: 0.3,
        opacity: 0,
        x: "-=2",
        stagger: -0.04,
        width: 0,
        ease: "power4.inOut",
      },
      "a"
    );

    tlInitials.fromTo(
      this.$dottedLetters,
      { opacity: 0, y: "-=16" },
      {
        duration: 0.3,
        opacity: 1,
        y: "0",
        stagger: -0.04,
        ease: "power4.inOut",
      },
      "b"
    );

    tlInitials.to(
      this.$spaceLetters,
      {
        duration: 0.3,
        width: 6,
        stagger: -0.04,
        ease: "power4.inOut",
      },
      "b"
    );

    return tlInitials;
  }

  initAnimation(isDesktop) {
    console.log(
      isDesktop
        ? "Desktop animation initialized"
        : "Mobile animation initialized"
    );
    const tlInitials = this.createInitialsTimeline();

    if (!isDesktop) {
      tlInitials.add(
        gsap.from([this.$name, this.$message], {
          duration: 0.3,
          opacity: 0,
          ease: "power4.inOut",
        })
      );
      tlInitials.delay(1).play();
    }

    ScrollTrigger.create({
      // markers: true,
      trigger: this.$welcomeSection,
      start: "top top",
      endTrigger: "footer.footer",
      end: "bottom top",
      pin: this.$el,
      pinSpacing: false,
      onEnter: () => this.playAnimation(tlInitials, isDesktop),
      onLeaveBack: () => this.reverseAnimation(tlInitials, isDesktop),
      onEnterBack: () => this.playAnimation(tlInitials, isDesktop),
      onLeave: () => this.reverseAnimation(tlInitials, isDesktop),
    });
  }

  playAnimation(timeline, isDesktop) {
    if (isDesktop && !timeline.isActive()) {
      timeline.play();
    }
  }

  reverseAnimation(timeline, isDesktop) {
    if (isDesktop && !timeline.isActive()) {
      timeline.reverse();
    }
  }
}