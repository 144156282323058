import labelManagerInstance from "./labelManager";

export default class Projects {
  constructor(el) {
    this.$container = el;
    this.$projects = el.querySelectorAll(".projects-grid__item");
    this.introAnimation();
    this.handleClick();
    this.handleMouseOver();
    // this.addCustomCursor();
  }

  // Initialize mouseover event handler
  handleMouseOver() {
    this.$projects.forEach((project) => {
      project.addEventListener("mouseover", (event) => {
        const projectTitle = project.attributes["data-title"];
        labelManagerInstance.updateLabelText(projectTitle.textContent);
      });
      project.addEventListener("mouseleave", (event) => {
        labelManagerInstance.setDefaultLabel();
      });
    });
  }

  // Initialize click event handler
  handleClick() {
    this.$projects.forEach((project) => {
      project.addEventListener("click", (event) => {
        // Find the currently active figure
        const activeFigure = project.querySelector("figure.active");
        if (!activeFigure) return;

        // Find the next sibling figure
        const nextFigure = activeFigure.nextElementSibling;

        // If a next figure exists, update classes
        if (nextFigure) {
          activeFigure.classList.remove("active");
          nextFigure.classList.add("active");
        } else {
          // If there is no next sibling, loop back to the first figure
          activeFigure.classList.remove("active");
          project.querySelector("figure").classList.add("active");
        }
      });
    });
  }

  addCustomCursor() {
    // Create the custom cursor element
    const customCursor = document.createElement("div");
    customCursor.classList.add("custom-cursor");
    customCursor.textContent = "CLICK";
    document.body.appendChild(customCursor);

    // Add mouse event listeners
    document
      .querySelectorAll(".projects-grid__images-container img")
      .forEach((image) => {
        image.addEventListener("mouseenter", () => {
          customCursor.style.display = "block";
        });

        image.addEventListener("mousemove", (e) => {
          customCursor.style.left = `${e.pageX + 10}px`;
          customCursor.style.top = `${e.pageY + 10}px`;
        });

        image.addEventListener("mouseleave", () => {
          customCursor.style.display = "none";
        });
      });
  }

  introAnimation() {
    this.$container.style.opacity = 1;

    const $firstImages = this.$container.querySelectorAll(".active"),
      tl = gsap.timeline({ delay: 0.5 });

    // Loop through each image and assign a random direction for the animation
    $firstImages.forEach((image) => {
      // Randomly choose either x or y axis for the animation
      const direction = Math.random() < 0.5 ? "x" : "y";
      const distance = Math.random() < 0.5 ? "+=5%" : "-=5%";

      // Add the image animation to the timeline
      tl.from(
        image,
        {
          [direction]: distance, // Set either 'x' or 'y' based on random selection
          opacity: 0,
          duration: 0.6,
          ease: "power4.inOut",
        },
        "-=0.5"
      ); // Adjust overlap timing as needed
    });
  }
}
