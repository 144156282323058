import labelManagerInstance from "./labelManager";

export default class Finding {

  constructor(el) {
    this.$finding = el;
    this.tl = this.initMasterTimeline();
    this.tl.add(this.findingTimeline(), "a");
  }

  // Init MasterTimeline
  initMasterTimeline() {

    return gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: this.$finding,
        start: () => "top 75%",
        onEnter: () => {
          let title = this.$finding.attributes["data-title"].textContent;
          labelManagerInstance.updateLabelText(title);
        },
        onEnterBack: () => {
          let title = this.$finding.attributes["data-title"].textContent;
          labelManagerInstance.updateLabelText(title);
        },
        onLeave: () => {
          labelManagerInstance.setDefaultLabel();
        },
        onLeaveBack: () => {
          labelManagerInstance.setDefaultLabel();
        },
      }
    });

  }


  // Animate background and text
  findingTimeline() {

    // Gsap defaults
    gsap.defaults({
      ease: "power2.out",
      duration: 1.2
    });

    const
      $tag = this.$finding.querySelector('.finding__tag'),
      $headline = this.$finding.querySelector('.finding__headline'),
      $text = this.$finding.querySelector('.finding__text'),
      $animation = this.$finding.querySelector('.finding__animation'),
      tl = gsap.timeline();

    tl.from([$tag, $headline, $text, $animation], {
      y: "+=8",
      opacity: 0.05,
      stagger: 0.1
    }, "a");

    return tl;

  }
}
