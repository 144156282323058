import LectureIntro from "./modules/lectureIntro";
import Finding from "./modules/finding";
import contactPageAnimations from "./modules/contactPageAnimations";
import StickyHeader from "./modules/stickyHeader";
import Projects from "./modules/projects";

// Lenis scroll
const lenis = new Lenis();
function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

// Projects
const projects = document.getElementById("projects");
if (projects) new Projects(projects);

// Lecture intro
const lectureIntro = document.querySelector(".lecture-intro");
if (lectureIntro) new LectureIntro(lectureIntro);

// Findings
const findings = document.querySelectorAll(".finding");
findings.forEach((finding) => {
  new Finding(finding);
});

// Contact page animations
const contactPage = document.querySelector(".contact-page");
if (contactPage) new contactPageAnimations(contactPage);

// Add "tight" class on body on "t" keyboard press
document.addEventListener("keydown", (e) => {
  if (e.key === "t") {
    document.body.classList.toggle("tight");
  }
});

// on document ready
document.addEventListener("DOMContentLoaded", () => {
  // Sticky header animations
  const stickyHeader = document.querySelector("header.header");
  if (stickyHeader) new StickyHeader(stickyHeader);
});


window.addEventListener('load', () => {
  ScrollTrigger.refresh();
});