export default class contactPageAnimations {

  constructor(el) {

    console.log("contactPageAnimations");
    this.$elem = el;
    this.tl = this.initMasterTimeline();
    this.tl.add(this.findingTimeline(), "a");
  }

  // Init MasterTimeline
  initMasterTimeline() {

    return gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: this.$elem,
        start: () => "top 75%",
      }
    });

  }


  // Animate background and text
  findingTimeline() {

    // Gsap defaults
    gsap.defaults({
      ease: "power2.out",
      duration: 0.3
    });

    const
      $headlines = this.$elem.querySelectorAll('.contact-section__headline'),
      $textItems = this.$elem.querySelectorAll('.contact-section__text > *'),
      $links = this.$elem.querySelectorAll('.contact__cta'),
      tl = gsap.timeline();

    tl.from([$headlines, $textItems, $links], {
      y: "+=8",
      opacity: 0,
      stagger: 0.1
    }, "a");

    return tl;

  }
}
