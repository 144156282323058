class LabelManager {
  constructor() {
    if (!LabelManager.instance) {
      this.labelElement = document.querySelector(".header__welcome-name");
      LabelManager.instance = this;
    }
    return LabelManager.instance;
  }

  updateLabelText(newText) {
    if (this.labelElement) {
      this.labelElement.textContent = newText;
    }
  }

  setDefaultLabel() {
    this.updateLabelText(this.labelElement.attributes["data-default"].textContent);
  }
}

const labelManagerInstance = new LabelManager();
Object.freeze(labelManagerInstance);
export default labelManagerInstance;
