import SplitText from '../vendor/gsap/SplitText';

export default class LectureIntro {

  constructor(el) {
    this.$el = el;
    this.tl = this.initTimeline();
  }


  initTimeline() {

    gsap.registerPlugin(SplitText);

    const
      $hl = this.$el.querySelector('.lecture-intro__headline'),
      splitHl = new SplitText($hl, {
        type: "chars"
      }),
      $cursor = this.$el.querySelector('.lecture-intro__headline-cursor'),
      $cloudTop = this.$el.querySelector('.lecture-intro__headline-cloud--top'),
      $cloudBottom = this.$el.querySelector('.lecture-intro__headline-cloud--bottom'),
      interval = 0.1,
      cursorBlinkInterval = 0.4,
      cursorBlinkCount = 2,
      $text = this.$el.querySelector('.lecture-intro__text'),
      $animation = this.$el.querySelector('.lecture-intro__animation'),
      $video = $animation.querySelector('video'),
      tl = gsap.timeline({
        delay: 1
      });

    // Make headline visibile when JS is enabled
    tl.set($hl, {
      opacity: 1
    });

    // Hide chars though
    tl.set(splitHl.chars, {
      opacity: 0
    });

    // Set initial position of the cursor on the first character of the headline
    tl.set($cursor, {
      x: () => {
        const computedStyle = window.getComputedStyle($hl);
        return parseFloat(computedStyle.paddingLeft);
      },
      y: 0,
    });

    // Initial blinking of the cursor
    tl.fromTo($cursor, {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      ease: "none",
      duration: cursorBlinkInterval,
      yoyo: true,
      repeat: cursorBlinkCount
    });

    // Character and cursor animation
    splitHl.chars.forEach((char, i) => {

      // Add a tween for each character's appearance
      tl.add(gsap.set(char, {
        opacity: 1,
        ease: "none"
      }), (cursorBlinkInterval * cursorBlinkCount) + i * interval);

      // Update the cursor position
      tl.add(() => {
        const charBounds = char.getBoundingClientRect();
        const headlineBounds = $hl.getBoundingClientRect();
        const xPosition = charBounds.left - headlineBounds.left + charBounds.width;
        const yPosition = charBounds.top - headlineBounds.top;
        $cursor.style.transform = `translate(${xPosition}px, ${yPosition}px)`;
      }, (cursorBlinkInterval * cursorBlinkCount) + i * interval);
    });

    // Hide the cursor immediately after the last character is revealed
    tl.to($cursor, {
      duration: interval,
      opacity: 0
    });

    // Cloud animation top
    tl.from($cloudTop, {
      duration: 0.6,
      scale: 0.8,
      x: "-=4",
      y: "+=4",
      rotate: "15deg",
      opacity: 0,
      ease: "power2.out"
    }, "cloud");

    // Cloud animation bottom
    tl.from($cloudBottom, {
      duration: 0.6,
      scale: 0.8,
      x: "+=4",
      y: "-=4",
      rotate: "15deg",
      opacity: 0,
      ease: "power2.out"
    }, "cloud+=0.3");

    // Text animation
    tl.from($text, {
      duration: 1.2,
      opacity: 0,
      y: "+=4",
      ease: "power2.out"
    }, "text-=0.6");

    // Animation
    tl.from($animation, {
      duration: 1.2,
      opacity: 0,
      y: "-=4",
      ease: "power2.out",
      onStart: () => {
        $video.currentTime = 0;
        $video.pause();
        setTimeout(() => {
          $video.play();
        }, 400);
      }
    }, "text-=0.6");

    return tl;

  }

  destroy() {
    console.log('LectureIntro.destroy()');
  }
}
